<template>
   <v-container fluid>
       <v-row justify="center">
           <v-col></v-col>
           <v-col cols="12" class="text-center">
               <v-icon size="90">mdi-city-variant</v-icon>
               <h2>¿Cual es el municipio/ciudad de su residencia?</h2>
           </v-col>
           <v-col cols="12" sm="12" md="4" class="pb-0">
               <v-autocomplete
                    v-model="municipio"
                    :items="municipios"
                    label="Municipio"
                    outlined
                    hide-details
                    @input="setMunicipio($event)"
                    @blur="$v.municipio.$touch()"
                ></v-autocomplete>
           </v-col>
           <v-col cols="12" class="text-center">
               <v-btn
                    class="ma-2 white--text"
                    rounded
                    color="indigo"
                    to="/q0"
                    >
                    <v-icon left>mdi-chevron-left</v-icon> Regresar
                </v-btn>
               <v-btn
                    class="ma-2 white--text"
                    rounded
                    color="indigo"
                    to="/personas"
                    :disabled="$v.$invalid"
                    >
                    Continuar <v-icon right>mdi-chevron-right</v-icon>
                </v-btn>
           </v-col>
       </v-row>
   </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
//eslint-disable-next-line
import { required } from 'vuelidate/lib/validators'

export default {
    
    mixins: [validationMixin],

    validations: {
      municipio: { required },
    },

    data() {
        return {
            municipio: '',
            municipios:[  
                "Buenavista",
                "Caimito",
                "Chal\u00e1n",
                "Colos\u00f3",
                "Corozal",
                "Cove\u00f1as",
                "El Roble",
                "Galeras",
                "Guaranda",
                "La Uni\u00f3n",
                "Los Palmitos",
                "Majagual",
                "Morroa",
                "Ovejas",
                "Sampu\u00e9s",
                "San Antonio de Palmito",
                "San Benito Abad",
                "San Juan de Betulia",
                "San Marcos",
                "San Onofre",
                "San Pedro",
                "Sinc\u00e9",
                "Sincelejo",
                "Sucre",
                "Tol\u00fa",
                "Tol\u00fa Viejo"
            ]
        }
    },

    mounted() {
        this.municipio = this.$store.state.municipio
        this.$store.state.progress = 5
    },

    computed: {
        municipioErrors () {
            const errors = []
            if (!this.$v.municipio.$dirty) return errors
            !this.$v.municipio.required && errors.push('Kilos de municipio es requerido.')
            //!this.$v.municipio.numeric && errors.push('Debe ser un número.')
            return errors
        },
    },

    methods: {
        setMunicipio(value) {
            this.$store.state.municipio = value
            this.$v.municipio.$touch()
        },
    },
}
</script>